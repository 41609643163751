.document-modal {
  & .MuiDialog-paper {
    min-width: 440px !important;
  }
  &__title {
    padding-bottom: 0 !important;
    & h2 {
      font-weight: bold !important;
      font-size: 20px !important;
      color: #333333 !important;
    }
  }
  &__input {
    display: block !important;
    &:first-child {
      margin-bottom: 20px;
    }
    & .MuiInput-formControl {
      width: 100% !important;
    }
  }
  &__action {
    justify-content: center !important;
    margin: 15px 0 !important;
    &-button {
      width: 120px;
      height: 40px;
      text-transform: none !important;
      font-weight: bold !important;
      &--green {
        background: #1B73C2 !important;
        color: #fff !important;
      }
      &--white {
        color: #333333 !important;
      }
    }
  }
}

.document-modal-editor {
  & .MuiDialogContent-root {
    padding-top: 0 !important;
  }
  & .MuiDialog-paper {
    width: 100% !important;
    max-width: 1500px !important;
    min-height: 90vh;
  }
}

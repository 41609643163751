.reduction {
  .MuiPaper-elevation1 {
    box-shadow: none!important;
  }
  .MuiDialog-paper {
    min-width: 80% !important;
    height: 90vh !important;
  }
  &-second {
    .MuiDialog-paper {
      min-width: 440px !important;
      height: auto !important;
    }
  }
  &__title h2{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  &__add-button {
    background: #1B73C2 !important;
    color: #fff !important;
    border: none !important;
    text-transform: none !important;
    font-weight: 700 !important;
    height: 40px;
  }
  &__actions {
    justify-content: center !important;
    &-second {
      margin: 20px 0 !important;
    }
  }
  &__action-button {
    border: 1px solid #333 !important;
    color: #333 !important;
    padding: 10px 30px !important;
    height: 40px;
    &--green {
      background: #1B73C2 !important;
      color: #fff !important;
      border: none !important;
      padding: 10px 30px !important;
      height: 40px;
     span {
       text-transform: none;
     }
    }
    &--border-none {
      border: none !important;
    }
    & span {
      text-transform: none !important;
      font-weight: 700;
    }
  }

  &__item {
    box-shadow: 0 10px 20px rgba(31, 32, 65, 0.1);
    min-width: 600px;
    height: 50px;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    &-block {
      width: 50%;
      height: 100%;
      &:last-child {
        display: flex;
        justify-content: space-between;
      }
    }
    &-text {
      color: #333;
    }
    &-interface {
      display: flex;
      justify-content: center;
      align-items: center;
      & svg {
        cursor: pointer;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    .MuiFormControl-root {
      margin-top: 20px;
      width: 100%;
    }
  }
  &__input {
    width: 100% !important;
    &:first-child {
      margin-right: 15px;
    }
  }
}
button:disabled,
button[disabled] {
  background-color: #ccc!important;
  color: #000000!important;
}
.jodit-wysiwyg {
  height: 58vh!important;
}

$active: #2290e0;

body {
  font-family: "Open Sans", sans-serif;
  color: #333333;
  margin: 0;
  box-sizing: border-box;
  background-color: #eceef2;
}

.mainContainer {
  margin-left: 200px;
}

header {
  box-sizing: border-box;

  &.MuiAppBar-colorPrimary {
    background: linear-gradient(
      187.04deg,
      #04a9a1 0.4%,
      #08a7ff 38.28%,
      #4376c8 97.12%
    );
  }

  .MuiButtonBase-root {
    text-transform: inherit;
    font-size: 14px;
    position: relative;
    margin-left: 30px;

    &::before {
      content: "";
      position: absolute;
      height: 22px;
      width: 1px;
      background-color: #fff;
      left: -9px;
    }
  }

  .white {
    color: #fff;
    text-decoration: none;
  }
}

.header__name {
  padding-left: 14px;
}

.profile-menu {
  position: absolute;
  bottom: 37px;
  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
  }

  &__profile-box {
    .MuiButtonBase-root {
      margin-top: 3px;
    }

    .MuiButton-endIcon {
      transform: rotate(180deg);
    }
  }

  &__profile {
    p {
      font-size: 12px;
      font-weight: 600;
    }
    .MuiAvatar-root {
      margin: 0 auto;
    }

    .MuiTypography-root {
      font-size: 14px;
    }
  }
}

.menuDrawer {
  position: relative;
  a {
    .MuiTypography-root {
      font-weight: bold;
    }
  }
  .MuiPaper-root {
    background: #1b73c2;
    max-width: 220px;
    width: 100%;
    border: none;
    box-sizing: border-box;
    color: #fff;
  }

  .logo-icon {
    text-align: center;
    cursor: pointer;
    position: relative;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      left: 0;
    }
  }

  .MuiListItemIcon-root {
    margin-right: 16px;
    min-width: 24px;
    display: flex;
    justify-content: center;
  }

  .MuiTypography-root {
    font-size: 14px;
  }

  .MuiList-root {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .MuiButtonBase-root {
    padding: 8px 14px;
  }

  .menuNav {
    color: #fff;
    text-decoration: none;
    display: block;
    border-radius: 4px;
    transition: all 0.3s ease;
    margin-bottom: 2px;

    svg {
      path {
        fill: #fff;
        transition: all 0.3s ease;
      }
    }

    &:hover,
    &.active {
      background-color: #fff;
      color: #1b73c2;

      svg {
        path {
          fill: #1b73c2;
        }
      }
    }
  }
}

.mainContent {
  padding: 30px 36px;
}

.view-document-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
}

.title {
  font-weight: bold;
  font-size: 28px;
}

.dateRight {
  display: flex;
  align-items: center;

  .MuiInputBase-root {
    max-width: 180px;
  }

  .arrow {
    padding-left: 3px;
    padding-right: 5px;
  }
}

button.MuiButton-containedPrimary {
  background-color: #1b73c2;
  text-transform: none;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: #1b73c2;
  }
}

button.MuiButton-containedSecondary {
  background-color: #ff3b30;
  text-transform: none;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}

.topRow {
  margin-bottom: 34px;
}

.tabs {
  &.MuiTabs-root {
    min-height: 34px;
  }

  .MuiButtonBase-root {
    text-transform: none;
    font-weight: 600;
    color: #9296a1;
    font-size: 16px;
    min-width: auto;
    min-height: 34px;
    line-height: 1;
    padding: 6px 0;
    margin: 0 16px;
    transition: all 0.3s ease;

    &:hover {
      color: $active;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.tabPanel {
  padding: 22px 0;
}

.tableContainer {
  border-radius: 8px 8px 0px 0px;
  position: relative;
  min-height: 45px;
  .tableButton {
    text-transform: none;
    font-weight: 600;
  }
}

.preloader-for-table {
  text-align: center;
}

.table {
  .tableHead {
    .MuiTableCell-root {
      font-weight: 600;
      min-width: 150px;
    }
  }

  .MuiTableCell-root {
    font-size: 16px;
  }

  .tableLink {
    display: flex;
    align-items: center;
    // justify-content: center;

    &Blue {
      color: #2290e0;
      text-transform: none;
      text-decoration: none;
    }

    &Red {
      color: #ff3b30;
      text-transform: none;
      text-decoration: none;
    }

    &Green {
      color: #31c85a;
      text-decoration: none;
    }
  }

  &__name {
    margin-left: 10px;
  }

  &__icon {
    display: flex;
    align-items: center;
    height: 16px;
    margin-top: 2px;
  }
}

.tableHead {
  background-color: #f9fafc;

  .MuiTableCell-root {
    font-size: 14px;
    color: #9296a1;
  }

  .MuiTableCell-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.sendPdf__modal {
  .MuiPaper-root {
    max-width: 350px !important;
  }
}
.modal {
  &.confirm {
    .MuiPaper-root {
      max-width: 300px;
      padding-top: 0;
      padding-bottom: 10px;
    }

    .modal__actions {
      justify-content: center;
      margin-top: 0;
    }

    .MuiDialogActions-root {
      justify-content: center;
    }

    .MuiDialogTitle-root {
      padding-bottom: 4px;
    }

    .MuiButtonBase-root {
      max-width: 119px;
      width: 100%;
    }

    .MuiDialogActions-spacing > :not(:first-child) {
      margin-left: 16px;
    }
  }

  &.processModal {
    .MuiPaper-root {
      max-width: 487px;
    }

    .MuiIconButton-colorPrimary {
      color: #afafaf;
      margin-right: 4px;

      &.Mui-checked {
        color: #31c85a;
      }
    }
  }

  .processModal__label {
    color: #898989;
    font-size: 14px;
    margin-left: 34px;
    margin-top: 5px;
  }

  .MuiButton-outlined {
    text-transform: none;
    font-weight: 600;
  }

  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 26px;
  }

  &__text {
    margin: 0;
    color: #757575;
  }
  .upload__pdf {
    margin: 20px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .pdfWrap {
      display: flex;
      align-items: center;
    }
    .removePdf {
      margin-top: 5px;
      margin-left: 5px;
      svg {
        path {
          fill: red;
        }
      }
    }
    svg {
      path {
        fill: #1b73c2;
      }
    }
    span {
      font-weight: bold;
      color: #1b73c2;
      text-decoration: underline;
    }
  }
  .MuiPaper-root {
    max-width: 600px;
    padding-top: 8px;
    padding-bottom: 24px;
    width: 100%;
  }

  .MuiDialogTitle-root {
    h2 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .MuiButton-textPrimary {
    color: #333333;
    text-transform: none;
  }

  .modal__actions {
    justify-content: center;
    margin-top: 12px;
  }

  .MuiInputBase-root {
    margin-bottom: 24px;
  }

  .radioControl {
    .MuiFormLabel-root {
      font-size: 14px;
    }

    .MuiFormGroup-root {
      flex-direction: row;
    }
  }

  .selectControl {
    margin-top: 24px;

    .MuiFormLabel-root {
      white-space: nowrap;
    }
  }

  .link {
    &:hover {
      text-decoration: none;
    }
  }
}

.justify-center {
  display: flex;
  justify-content: center;
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  padding-left: 325px;

  &__guest {
    text-align: center;
    margin-top: 5rem;
    font-weight: 600;
    font-size: 20px;
  }

  .dataMatches {
    font-weight: 700;
    font-size: 16px;
    color: #00e03d;
    display: flex;
    align-items: center;
    margin-top: 14px;

    &.error {
      color: #ff3b30;
    }

    .MuiSvgIcon-root {
      margin-right: 10px;
    }
  }

  .password {
    background-color: #fff;
    max-width: 325px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding-bottom: 24px;
    .upload__pdf {
      display: block;
      padding-bottom: 30px;
      text-align: center;
      margin: 0 auto;
      align-items: center;
      color: #2290e0;
      font-weight: bold;
      > span {
        color: #2290e0;
        margin-bottom: 5px;
      }
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: #31c85a;
    }
    &__back {
      border-bottom: 1px solid #afafaf;
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 12px;
      cursor: pointer;
      box-sizing: border-box;

      span {
        padding-left: 22px;
      }
    }

    &__edit {
      text-align: right;
      text-transform: none;
      color: #31c85a;
      float: right;
      margin-right: 10px;
      font-weight: 600;
      font-size: 16px;
    }

    &__name {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 12px;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &__content {
      padding-top: 16px;
      padding-bottom: 32px;
      padding-left: 12px;
      box-sizing: border-box;

      .MuiFormControl-root {
        margin-bottom: 8px;
        box-sizing: border-box;
      }

      .MuiInputBase-root {
        padding-right: 24px;
      }
    }

    &__imgRow {
      padding-top: 7px;
    }

    &__imgItem {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      img {
        margin-right: 10px;
        cursor: pointer;
        width: 100%;
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  .authClientData {
    background-color: #233148;
    max-width: 325px;
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    &__back {
      border-bottom: 1px solid #afafaf;
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 12px;
      cursor: pointer;
      box-sizing: border-box;

      svg path {
        fill: #fff;
      }

      span {
        padding-left: 22px;
      }
    }

    .clientInfo {
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #afafaf;

      .MuiAvatar-root {
        width: 80px;
        height: 80px;
        background: #019ae8;
        border: 4px solid #dddddd;
        box-sizing: border-box;
        font-size: 34px;
        font-weight: 600;
        margin-right: 16px;
      }

      &__span {
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 2px;
      }

      &__name {
        font-weight: 600;
        font-size: 20px;
      }
    }

    .clientList {
      box-sizing: border-box;
      padding: 16px;
      padding-right: 0;
      padding-left: 38px;
      border-bottom: 1px solid #afafaf;
      position: relative;

      .MuiListItem-root {
        border-radius: 4px 0px 0px 4px;
        transition: all 0.3s ease;

        &:hover {
          background: #656f7f;
        }
      }

      .MuiTypography-root {
        font-size: 18px;
      }

      .MuiListItemAvatar-root {
        min-width: 40px;
        height: auto;
        display: flex;
      }

      .MuiSvgIcon-root {
        color: #fff;
      }
    }

    .callTransfer {
      padding: 35px 27px 35px;

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      &__icon {
        display: flex;
        margin-right: 16px;
      }

      &List {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .MuiListItem-root {
          background: #656f7f;
          border-radius: 4px;
          height: 52px;
          max-width: 263px;
          width: 100%;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .MuiListItemAvatar-root {
          min-width: 40px;
          height: auto;
          display: flex;
        }

        .MuiTypography-root {
          font-weight: 600;
          line-height: 1.2;
        }
      }
    }
  }
}

.prevIcon {
  position: absolute;
  right: -5px;
}

.passwordModal {
  position: fixed;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  left: 325px;
  z-index: 999;
  overflow: hidden;
  color: #3333;
  top: 14%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 900px;
  height: 510px;
  overflow-y: scroll;

  &.clientInfo {
    position: static;
    margin-bottom: 32px;
  }

  .modalClose {
    z-index: 9999;
  }

  &__header {
    background: #f5f5f5;

    .MuiButtonBase-root {
      border-bottom: 2px solid transparent;
      font-size: 17px;
      text-transform: none;
      padding: 24px 32px 0 32px;
      color: #333333;
      font-weight: 600;

      &.Mui-selected {
        border-color: $active;
        color: $active;
      }
    }
  }

  &__content {
    padding: 32px 0 10px 32px;
    background-color: #fff;
    color: #333333;
  }

  .personInfo {
    display: flex;
    max-height: 539px;
    // overflow-y: auto;
    &__imgRow {
      margin-right: 22px;
      max-width: 160px;
      width: 100%;

      img {
        display: block;
        margin-bottom: 10px;
        max-width: 160px;
        width: 100%;
        cursor: pointer;
      }
    }

    &Row {
      width: 100%;
    }

    &__item {
      width: 100%;
      border-bottom: 1px solid #c6c6c6;
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-size: 15px;

      &:last-child {
        border: none;
      }
    }

    &__label {
      font-weight: bold;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
    }
    &__phone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-edit {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #2290e0;
        background: none;
        border: none;
        cursor: pointer;
        height: 20px;
        svg {
          margin-right: 12px;
        }
      }
    }
    &__input {
      padding: 0;
      border: none;
      font-size: 15px;
      color: #333333;
      font-family: "Open Sans", sans-serif;
      &:focus {
        border: none;
        outline: none;
      }
      &_nochange {
        pointer-events: none;
      }
    }
  }

  .personInfo__phone-edit {
    margin-right: 14px;
  }

  .bankData {
    &__title {
      font-weight: 700;
      font-size: 20px;
      margin: 0;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 20px 0;
    }

    .MuiFormControl-root {
      margin-bottom: 14px;
    }

    .MuiInputLabel-shrink {
      transform: translate(0, 0) scale(1);
      font-size: 15px;
      font-weight: 700;
      color: #333;
    }

    .MuiInputBase-root.Mui-disabled {
      color: #333333;
    }

    .MuiInputBase-root {
      padding-bottom: 10px;
    }

    .MuiInput-underline.Mui-disabled:before {
      border-bottom: 1px solid #c6c6c6;
    }

    &__row {
      display: flex;
      justify-content: center;
      padding: 12px;

      .MuiButton-text {
        text-transform: none;
        color: #333333;
        margin-right: 15px;
      }
    }

    &__add {
      margin-right: 15px;
      text-transform: initial;

      &.MuiButton-contained {
        background-color: transparent;
      }
    }
  }
}

.documentModal {
  position: fixed;
  background: #fff;
  z-index: 999;
  left: 325px;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: 100%;
  padding: 22px 64px 22px 32px;
  overflow-y: auto;

  .documentModal__tabs {
    .MuiButtonBase-root {
      text-transform: none;
      font-weight: 700;
      font-size: 17px;
      padding: 0;
      margin: 0 12px;
      min-width: auto;
    }

    &.MuiTabs-root,
    .MuiTab-root {
      min-height: 36px;
    }
  }
}

div.tabs {
  .MuiButtonBase-root {
    text-transform: none;
    font-weight: 700;
    font-size: 17px;
    padding: 0;
    margin: 0 12px;
    min-width: auto;
  }

  &.MuiTabs-root,
  .MuiTab-root {
    min-height: 36px;
  }
}

.credit-history {
  margin: 10px 0 24px 0;
  border-bottom: 1px solid #c6c6c6;

  &--last {
    margin-bottom: 0;
    border-bottom: 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 15px;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    span {
      margin-right: 25px;
    }

    a {
      color: #019ae8;
      text-decoration: none;
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
      }
    }
  }
}

.passwordData {
  &__item {
    margin-bottom: 8px;
  }

  &__label {
    font-size: 13px;
    color: #838383;
    margin-bottom: 6px;
  }

  &__textField {
    font-size: 14px;
    padding-bottom: 8px;
    border-bottom: 1px solid #afafaf;
  }

  .MuiInputBase-root {
    &.Mui-disabled {
      color: #333333;
    }
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom: 1px solid #afafaf;
  }
}

.btnRow {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-top: 1px solid #afafaf;
  padding-top: 14px;

  button {
    margin: 8px;
  }
}

.iframe {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  &Preview {
    position: absolute;
    top: 15px;
    right: 15px;

    img {
      border-radius: 10px;
    }
  }

  &__icons {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    span {
      cursor: pointer;
      margin: 10px;
    }
  }

  &Content {
    width: 100%;
    height: 100vh;
    border: none;
    box-sizing: border-box;
  }
}

.imageModal {
  position: absolute;
  bottom: 8%;
  transform: translateY(0);
  max-width: 375px;
  width: 100%;
  background-color: #fff;
  margin-left: 24px;
  margin-right: 24px;
  min-height: 342px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 999;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__stepper {
    .MuiButton-root {
      .MuiSvgIcon-root {
        font-size: 40px;
        color: #555555;
      }

      &.Mui-disabled {
        .MuiSvgIcon-root {
          color: #cccccc;
        }
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

button.modalClose {
  position: absolute;
  top: 5px;
  right: 5px;
}

.textField {
  position: relative;

  &__count {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
  }

  &__label {
    color: #2290e0;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -24px;
    color: #2290e0;
    font-weight: 600;
  }

  .react-code-input {
    input {
      &:focus {
        outline-offset: 0;
        outline: 0;
        border-color: $active !important;
      }
    }
  }
}

.search {
  .MuiInputBase-root {
    background: #f8fafc;
    border-radius: 2px;
  }
}

.clientsTable {
  max-height: 54vh;

  &__icon {
    svg {
      path {
        fill: #757575;
      }
    }
  }
}

.personCard {
  max-width: 997px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 30px 10px;
  display: flex;
  margin-bottom: 40px;

  &__identification {
    margin-right: 25px;
    text-align: center;
    font-weight: 600;
    color: #ff0000;
    margin-top: 26px;
  }

  &__img {
    margin-right: 25px;
    max-width: 264px;
  }

  .passwordImg {
    max-width: 260px;
  }

  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }
}

.personBox {
  display: flex;

  &__item {
    margin-right: 40px;
  }

  &__group {
    margin-bottom: 16px;
  }

  &__label {
    font-weight: 700;
    margin-bottom: 2px;
  }
}

.cardNumber {
  display: flex;
  margin-top: 16px;

  &__item {
    display: flex;
    margin-right: 32px;
  }

  &__label {
    font-weight: 700;
    margin-right: 12px;
  }

  p {
    margin: 0;
    margin-bottom: 3px;
  }
}

.tableTop {
  margin-bottom: 32px;

  &__title {
    font-size: 24px;
    font-weight: 600;
  }
}

.companyInfoToggle {
  margin-bottom: 32px;

  .expansionPanel {
    &.MuiExpansionPanel-root {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 16px;
      border-radius: 0;
      padding: 4px 14px;

      &::before {
        display: none;
      }
    }

    &__title {
      font-weight: 700;
    }

    &__label {
      font-weight: 700;
      margin-bottom: 4px;
    }

    p {
      margin: 0;
    }

    &__square {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 157px;
        height: 157px;
        background-color: #c4c4c4;
        margin-right: 24px;
        margin-bottom: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__details {
      width: 100%;
    }
  }

  .officialAdd {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    margin-top: 16px;
  }
}

.paymentOrder {
  .MuiDialog-paper {
    max-width: 725px;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    display: block;
    line-height: 1.2;
  }

  &__subTitle {
    font-weight: 600;
  }

  .paymentOrder__actions {
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 24px;
  }

  .MuiButton-textPrimary {
    color: #333333;
    text-transform: none;
  }

  .MuiSelect-root {
    min-width: 170px;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #50bf34;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #50bf34;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }

  .MuiInputBase-root.Mui-disabled {
    color: #333333;
  }
}

.accountType {
  font-size: 12px;
  color: #757575;
  margin-left: 5px;
}

.paymentContent {
  border-bottom: 1px solid #50bf34;

  &__title {
    font-size: 20px;
    background: #2aa3ef;
    border-radius: 0px 8px 8px 0px;
    height: 34px;
    display: flex;
    align-items: center;
    max-width: 180px;
    box-sizing: border-box;
    color: #fff;
    padding-left: 18px;
    padding-right: 18px;
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: 16px;
  }

  &__score {
    max-width: 220px;
  }

  &__item {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__desc {
    color: #757575;
    margin: 6px;
    margin-right: 12px;
    white-space: nowrap;
  }

  &__formControl {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 26px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.paymentWidth {
      .MuiFormControl-root {
        max-width: 275px;
      }
    }

    &.summaWidth {
      .MuiFormControl-root {
        .MuiInputBase-input {
          text-align: right;
        }

        max-width: 194px;
      }
    }
  }

  &__row {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }

  .width {
    width: 100%;
  }

  &__group {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 26px;

    .summaWidth {
      margin: 0;
      width: auto;
    }
  }
}

.paymentForm {
  padding-top: 26px;
  border: none;

  &__title {
    font-size: 20px;
    background: #50bf34;
    border-radius: 0px 8px 8px 0px;
    height: 34px;
    display: flex;
    align-items: center;
    max-width: 180px;
    box-sizing: border-box;
    color: #fff;
    padding-left: 18px;
    padding-right: 18px;
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: 24px;
  }

  &__row {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__desc {
    max-width: 136px;
    width: 100%;
    color: #757575;
  }

  &__currency {
    max-width: 106px;
    padding-left: 6px;
    overflow: hidden;
  }

  &__name {
    color: #000000;
  }
}

.modalButtonGroup {
  text-align: center;

  .MuiButtonBase-root {
    margin-left: 8px;
    margin-right: 8px;
    text-transform: none;
    font-weight: 600;

    &.MuiButton-outlinedPrimary {
      border: 1px solid #31c85a;
      box-sizing: border-box;
      border-radius: 4px;
      color: #31c85a;
    }
  }
}

button.addStaffModalLink {
  text-transform: none;
}

.count {
  font-size: 14px;
  color: #9296a1;
}

.editIcon {
  color: #31c85a;
}

.deleteIcon {
  color: #ff3b30;
}

.colorGroup {
  margin-bottom: 32px;

  &__title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .MuiFab-root {
    box-shadow: none;
    margin: 7px;
    width: 44px;
    height: 44px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .activeColor {
    color: #ffffff;
  }
}

.iconGroup {
  margin-bottom: 32px;
  position: relative;

  &__title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  .iconGroup__select {
    max-width: 60px;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid #c6c6c6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 0;
    position: relative;
    cursor: pointer;
  }

  &__label {
    color: #838383;
    margin-left: 12px;
    font-size: 14px;
  }

  .downIcon {
    position: absolute;
    right: 2px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #000000;
    opacity: 0.54;
  }
}

.iconModal {
  .modalClose {
    top: 18px;
    right: 8px;
    color: #333333;
  }

  .MuiPaper-root {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 26px 20px 20px;
    max-width: 424px;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 68px;
    top: 30px;
    z-index: 9;
    background-color: #fff;
    margin-left: 8px;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 20px;
  }

  .icon {
    margin: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active,
    &:hover {
      color: $active;
    }

    &:first-child {
      margin-left: 0;
    }
    &:nth-child(10n) {
      margin-right: 0;
    }
    &:nth-child(11n) {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__row {
    text-align: center;
    margin: auto;
    margin-top: 30px;
  }
}

.registerUserText {
  font-weight: 600;
  font-size: 18px;
}

.documentHistory {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #c6c6c6;

  &__title {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 24px;
  }
}
.child-documents {
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  width: 137px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;

  &__checkbox {
    text-align: right;
  }
  &__content {
    text-align: center;
  }
  &__radio {
    display: flex;
    align-items: center;
    margin-top: auto;
    .MuiRadio-colorSecondary.Mui-checked {
      color: #019ae8 !important;
      // margin-right: 10px;
      // margin-left: 10px;
      padding: 9px;

      .MuiIconButton-label {
        display: flex;
        background-color: #019ae8;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }
    .MuiRadio-root {
      position: static !important;
    }
    input {
      width: 20px;
      height: 20px;
      display: none;
    }
  }
}
.contract {
  margin-left: -4px;
  margin-right: -4px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;

  .back-document {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    span {
      font-size: 16px;
      color: #000000;
    }

    margin-bottom: 10px;
  }

  .contract__item {
    width: 138px;
    min-height: 138px;
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 8px;
    position: relative;
    margin: 4px;
  }

  .MuiRadio-root {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__icon {
    width: 100%;
    margin-bottom: 12px;
  }

  &__title {
    color: #41516f;
    font-size: 12px;
  }
}

.documentIframe {
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  padding: 30px;
  padding-top: 56px;
}

.contractModal {
  .MuiPaper-root {
    box-sizing: border-box;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialog-paper {
    max-width: 100%;
  }
}

.paginations {
  margin-top: 30px;

  .MuiButtonBase-root {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border: 0.5px solid #dadada;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;

    .MuiPaginationItem-icon {
      font-size: 24px;
      color: #ababab;
    }

    &:hover {
      background-color: #fff;
    }

    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: #2290e0;
      border-color: #2290e0;
      color: #ffffff;
    }
  }

  .MuiPaginationItem-ellipsis {
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 2px;
    min-width: 30px;
  }
}

.autocomplate {
  &__option {
    display: flex;
    align-items: center;

    &.bank__option {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__label {
    font-size: 12px;
    color: #696c70;
    margin-left: 10px;
  }

  .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiInputBase-formControl {
    padding: 2px 6.5px;
  }
}

.registrationBtn {
  max-width: 263px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;

  .MuiButtonBase-root {
    height: 50px;
    background-color: #656f7f;
    border-radius: 4px;
    font-size: 16px;

    &:hover {
      background-color: #656f7f;
    }
  }
}

.content {
  padding: 30px;

  .title {
    font-size: 28px;
    font-weight: 700;
    color: #333333;
    white-space: nowrap;
  }
}

.empty-tags__input {
  div {
    width: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.tags-modal {
  margin-top: 50px;
  text-align: center;
}

// .MuiPaper-root {
//   // width: 100%;
// }

.modal-paper {
  min-height: 150px;
  // width: 45%;
  max-width: 500px;
  background-color: #fff;
  padding: 30px;
  outline: none;
  border-radius: 20px;
  margin: 0 auto;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }
  .previewDoc {
    color: #007aff;
    text-decoration: underline;
    display: block;
    margin-top: 15px;
  }
  .doc-button-wrap {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0px;
    button {
      // width: 49%;
      // border-radius: 8px;
      color: #ffffff;
      &:first-child {
        margin-right: 1%;
        // width: 49%;
        color: #007aff;
        // border: 1px solid #007AFF;
      }
    }
  }
}

.header {
  background-color: #fff;
  min-height: 56px;
  box-shadow: 0px 0.4px 0px #c4c4c4;
}
.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 56px;
  padding: 0 20px;
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
  }
  .header__exit {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-right: 10px;
      color: #1b73c2;
      font-size: 14px;
    }
  }
}
.MuiButton-contained {
  background-color: #1b73c2;
  span {
    font-weight: 600;
  }
  box-shadow: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.MuiTableContainer-root {
  background-color: #fff;
}
#Capa_1 {
  width: 24px;
  height: 24px;
}

.pointer {
  cursor: pointer;
}
.signedContract {
  background: #ffffff;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 15px;
  color: #757575;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  z-index: 99;
  left: 10px;

  max-width: 358px;
  right: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;

  &__item {
    border-bottom: 1px solid #c6c6c6;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__label {
    padding-bottom: 4px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .signed__signature {
    padding-top: 8px;
    padding-bottom: 8px;
    span {
      font-weight: 600;
    }
  }

  .signed__item {
    display: flex;
    margin-top: 5px;
    svg {
      margin-right: 3px;
    }
    span {
      font-size: 11px;
      margin-top: 1px;
    }
  }
}
.center-preloader {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.filterDocument {
  .MuiMenu-paper {
    top: 125px !important;
  }
}
.date-document-table {
  span {
    margin-right: 10px;
  }
}
.MuiLinearProgress-colorPrimary {
  width: 100%;
  position: absolute !important;
}

.buttonWrap_document {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 50px;
  .MuiButton-contained {
    span {
      color: #fff;
    }
    margin-left: 20px;
  }
}
.commentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-height: 100px;
  .MuiFormControl-root {
    width: 100%;
  }
}
.executorInfo {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  .executorName {
    font-weight: 600;
    margin-right: 5px;
  }
}
.btn__wrap-group {
  .MuiButton-contained {
    background-color: #31c85a !important;
  }
  .MuiButton-outlinedSecondary {
    background-color: #ff3b30 !important;
    color: #ffffff !important;
    text-transform: none;
  }
}
.viewDoc {
  svg {
    path {
      fill: #31c85a;
    }
  }
}

.document-table {
  &__actions {
    img {
      cursor: pointer;
    }
    img:first-child {
      margin-right: 24px;
    }
    &--loading,
    &--loading > img {
      cursor: progress;
    }
  }
}

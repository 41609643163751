
.organization {
  padding: 30px;
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #333333;
    white-space: nowrap;
  }
  .editOrganization {
    margin-left: 20px;
    cursor: pointer;
  }
  button {
    background-color:#31C85A;
    span {
      text-transform: none;
      color: #fff;
    }
    &:hover {
      background-color: #31C85A;
    }
  }
}
button {
  &.green-button {
    background-color:#31C85A;
    span {
      text-transform: none;
      color: #fff;
    }
    &:hover {
      background-color: #31C85A;
    }
  }
}
.addOrganizationModal {
  .modalButtonGroup {
    padding-bottom: 20px;
  }
  color: #333333;
  .MuiPaper-root {
    max-width: 772px;
    min-width: 662px;
    box-sizing: border-box;
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 32px;
  }
  .deportamentTitle {
    margin-bottom: 14px;
  }
  .addOrganizationModal__content {
    padding: 40px 40px 20px;
  }
  .MuiFormControl-root {
    margin-bottom: 22px;
  }
  .parentDeportament {
    color: #757575;
    font-size: 12px;
    margin-bottom: 32px;
    strong {
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
.departmentBox, .documentBoxModal {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 14px 18px;
  min-width: 306px;
  max-width: 306px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  min-height: 106px;
  cursor: pointer;
  color: #333333;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-right: 25px;
  margin-bottom: 25px;

  transition: all .25s ease;
  &:hover {
    .departmentIcons, .documentIconEdit {
      display: flex;
      z-index: 999;
    }
    transform: scale(1.05);
    transition: all .25s ease;
  }
  .departmentIcons {
    display: none;
  }
  .documentIconEdit {
    position: absolute;
    display: none;
    right: 15px;
    top: 15px;
  }
  a {
    text-decoration: none;
    color: #333333;
  }
  .departmentTitle, .documentTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #202020;
    min-height: 40px;
  }
  .departmentBox__wrap, .documentBox__wrap {
    display: flex;
    margin-top: 10px;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  .departmentBox__author, .documentBox__author {
    color: #202020;
    font-size: 16px;
  }
}
.departmentBox__title {
  justify-content: space-between;
  align-items: flex-start!important;
  .departmentIconDelete {
    margin-right: 5px;
  }
}

.documents__button {
  button {
    display: block;
  }
}
.select-tag {
  width: 100%!important;
  border: 0;
  border-bottom: 1px solid #ccc;
  outline: none;
  font-size: 17px;
  &:focus {
    border: 0
  }
  option {
    font-size: 17px;
  }
}
.selectedName {
  font-size: 17px;
  color: #333333;
  font-weight: 600;
  margin-top: 30px;
}

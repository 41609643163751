.documentBox {
  min-width: 137px;
  max-width: 137px;
  min-height: 137px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  border-radius: 10px;
  padding: 4px;
  margin-left: 10px;
  .document-display {
    margin-top: 35px;
    span {
      display: block;
      margin-top: 17px;
      font-size: 12px;
      color: #41516F;
    }
  }
  .documentBox__icons {
    display: flex;
    justify-content: space-between;
    padding: 0 3px;
    align-items: center;
    opacity: 0;
    top: 5px;
    svg {
      cursor: pointer;
      margin-right: 5px;
    }
  }
  &:hover {
    border: 1px solid #019AE8;
    transition: all .25s ease;
    .documentBox__icons {
      opacity: 1;
    }
  }
}

.services {
  border-top: 1px solid #C6C6C6;
  flex-wrap: wrap !important;
  &-wrap {
    margin-top: 30px !important;
  }
  &__title-wrap {
    display: flex;
    justify-content: space-between;
  }
  &__add-button {
    background-color: #1B73C2!important;
    color: #fff !important;
    border: none !important;
    text-transform: none !important;
    font-weight: 700 !important;
    height: 40px;
    max-width: 100px !important;
    width: 100% !important;
  }
  &__item {
    box-shadow: 0 10px 20px rgba(31, 32, 65, 0.1);
    background-color: #fff;
    border-radius: 4px !important;
    border: 1px solid #E8E8E8;
    padding: 15px !important;
    margin: 0 10px 20px !important;
    cursor: pointer;
    &-title {
      font-size: 16px !important;
      color: #202020 !important;
      font-weight: 600 !important;
      margin-bottom: 15px !important;
    }
    &-body {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 10px;
    }
  }
}

.ql-container.ql-snow {
  min-height: 100vh;
}
.document-list {
  color: #000;
  margin: 0 auto;
  .MuiInputLabel-formControl {
    position: static!important;
    text-align: center;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  li {
    color: #000;
  }
  .MuiListItem-root {
    padding: 0;
  }
  .MuiListItemIcon-root {
    min-width: 30px;
  }
}

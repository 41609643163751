.preview-document {
  .MuiDialog-paperWidthSm {
    width: 80%;
    height: auto;
    max-width: 100% !important;

    p {
      line-height: 1.5 !important;
      span {
        line-height: 1.5!important;
      }
    }
  }
}
.MuiDialog-paper {
  overflow-y: hidden!important;
}
